import React from "react";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header className="siteHeader">
        <h1>Michael Grinthal</h1>
        <h2>Website under development</h2>
      </header>
    </div>
  );
}

export default App;
